<template>
  <div>
    <b-row align-h="center" v-if="dataLoading" class="mt-2">
      <b-spinner
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row align-h="center" v-else>
      <b-col xl="7" lg="7" md="8" cols="12">
        <b-card class="pr-1">
          <h3 class="mt-1">Remarks</h3>
          <hr />
          <div
            v-for="(item, ind) in myObj"
            :key="ind"
            style="display: flex; align-items: baseline"
          >
            <b-col md="2" class="p-0">
              <b-form-group
                label="Percentage From"
                invalid-feedback="Perc from is required."
                ref="from"
              >
                <b-form-input
                  :id="'from' + ind"
                  type="number"
                  :ref="'from' + ind"
                  placeholder=""
                  v-model="item.percentageFrom"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" class="pr-0">
              <b-form-group
                label="Percentage To"
                invalid-feedback="Perc to is required."
                ref="to"
              >
                <b-form-input
                  type="number"
                  placeholder=""
                  ref="to"
                  v-model="item.percentageTo"
                />
              </b-form-group>
            </b-col>
            <b-col md="7" class="pr-0">
              <b-form-group
                label="Remarks"
                invalid-feedback="Remarks is required."
                ref="remarks"
              >
                <b-form-input
                  placeholder=""
                  ref="remarks"
                  v-model="item.remarks"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" class="mt-1">
              <b-button
                v-if="ind == myObj.length - 1"
                @click="AddValue(ind + 1)"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon size="20" icon="PlusIcon" />
              </b-button>
              <b-button
                v-else
                @click="removeValue(item, ind)"
                variant="outline-danger"
                class="btn-icon"
              >
                <feather-icon size="18" icon="XIcon" />
              </b-button>
            </b-col>
          </div>
          <b-button
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="Add()"
            :disabled="request"
            block
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-if="request == false"> Save </span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  VBTooltip,
  BFormGroup,
  BForm,
  BSpinner,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BForm,
    BSpinner,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
  },
  data() {
    return {
      rights: {},
      check: 1,
      request: false,
      dataLoading: false,
      myObj: [
        {
          id: 0,
          percentageFrom: 0,
          percentageTo: 0,
          remarks: "",
          otherRemarks: "",
          campusID: this.$store.state.userData.cId,
        },
      ],
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    handleEvent(event) {
      // console.log(event);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (this.check == 1) {
          this.check = 0;
          this.Add();
        }
      }
    },
    AddValue(ind) {
      this.myObj.push({
        id: 0,
        percentageFrom: 0,
        percentageTo: 0,
        remarks: "",
        otherRemarks: "",
        campusID: this.$store.state.userData.cId,
      });
      setTimeout(() => {
        let elem = document.getElementById("from" + ind);
        elem.focus();
        elem.select();
      }, 100);
    },

    async removeValue(item, ind) {
      if (item.id == 0) {
        this.myObj.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Remarks/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj.splice(ind, 1);
        // console.log(this.myObj);
      }
    },

    CheckValues() {
      let state = true;
      this.myObj.forEach((el) => {
        el.percentageFrom = parseFloat(el.percentageFrom);
        el.percentageTo = parseFloat(el.percentageTo);
        if (
          isNaN(el.percentageTo) ||
          isNaN(el.percentageFrom) ||
          el.percentageFrom >= 100 ||
          el.percentageFrom < 0 ||
          el.percentageTo <= 0 ||
          el.percentageTo > 100 ||
          el.remarks == ""
        ) {
          state = false;
        }
      });
      return state;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Remarks?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      if (result == "NotFound" || result.length == 0) {
        this.myObj = [
          {
            id: 0,
            percentageFrom: 0,
            percentageTo: 0,
            remarks: "",
            otherRemarks: "",
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else {
        this.myObj = result;
      }
      // console.log("obj", this.myObj);
      this.dataLoading = false;
      setTimeout(() => {
        let first = document.getElementById("from0");
        first.focus();
        first.select();
      }, 100);
    },

    async Add() {
      if (this.CheckValues() == false) {
        this.check = 1;
        return this.$bvToast.toast("Please enter the details correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        //Add
        // console.log("obj:", this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Remarks/Save?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Remarks added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
        this.request = false;
        this.check = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
